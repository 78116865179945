<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <!-- 页面头部查询模块 -->
    <template #search>
      <a-space>
        <a-form-item label="">
          <a-range-picker v-model:value="searchReset.time" @change="timeChange" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" placeholder="创建时间" style="width:100%" />
        </a-form-item>
        <a-form-item label="">
          <!-- $store.state.enumAll.DropPublicEnum -->
          <div v-ClickOutside="conClickOutside" >
            <a-input v-model:value="searchForm.fallReason" @focus="showBox = true" id="user" placeholder="掉落原因" class="ransport_search_input" />
            <div class="listBox" v-if="showBox">
              <div  class="itembox  cur-p" @click="cleItem(item)"  v-for="item in $store.state.enumAll.DropPublicEnum" :key="item.id">
                           <span>{{ item.label }}</span>
              </div>
          </div>
          </div>

        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchReset.ChannelTypeEnum" :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" @change="setChannel" placeholder="渠道来源" />
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.intentionLevel" class="ransport_search_input" placeholder="意向等级">
            <a-select-option v-for="item in $store.state.app.labelAll.INTENTION_LEVEL" :key="item.value"> {{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.clueType" class="ransport_search_input" placeholder="线索分类" :options="$store.state.enumAll.ClueTypeEnum">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.mobile" type="number" placeholder="电话" class="ransport_search_input" />
        </a-form-item>
        <a-form-item label="" >
          <a-input v-model:value="searchForm.wechat"  placeholder="微信" class="ransport_search_input" />
        </a-form-item>
        <a-button :loading="searchLoading" type="primary" @click="onSearch" :disabled="searchLoading">
          查询
        </a-button>
        <a-button @click="reset">
          重置
        </a-button>
      </a-space>
    </template>
    <template #totalbar>
      <ExclamationCircleTwoTone twoToneColor="#427bb1" /> 你当天领取总数： <a-tag color="#1890ff">{{ addTotalNum.highSeasTotalNum }}</a-tag> 条，已领 <a-tag color="#1890ff">{{ addTotalNum.highSeasNum }}</a-tag> 条
    </template>
    <template #toolReload>
    </template>
    <!-- 上方的左边按钮 -->
    <template #toolbarLeft>
      <a-space>
        <a-button @click="batchImportant" class="tool-color-344563"> 批量导入 </a-button>
        <a-button :disabled="searchReceive" @click="searchReceiveShow = true" class="tool-color-344563">
          <icon-font type="hy-lingqu1" class="tool-icon" />
          领取客户
        </a-button>
        <!-- <a-button @click="searchAddShow=true" class="tool-color-344563">
          <icon-font type="hy-tianjia" class="tool-icon" /> 新增线索
        </a-button> -->
        <!--        <a-button @click="searchPhoneShow=true" class="tool-color-344563">-->
        <!--          <icon-font type="hy-dianhua" class="tool-icon" />-->
        <!--          领取主叫客户-->
        <!--        </a-button>-->
        <a-button :disabled="searchReceive" @click="mandatory = true" class="tool-color-344563">
          <RetweetOutlined />
          强制分配
        </a-button>
      </a-space>
      <a-modal v-model:visible="mandatory" width="40%" title="强制分配" @ok="distribution">
        <div> 选择负责人<span style="color:#f00">*</span></div>
        <a-select style="width:100%" mode="multiple" :not-found-content="fild ? undefined : null" :filter-option="false" show-search placeholder="请输入员工姓名或关键字" @inputKeyDown="search" @search="assignment" @change="memberChange">
          <a-select-option v-for="item in options" :key="item.id" :value="item.name">{{ item.name }}-{{ item.no }}</a-select-option>
          <template v-if="fild" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
        <span style="color:#f00;font-size:14px">在输入完成后，按下回车搜索</span>
      </a-modal>
      <a-modal v-model:visible="searchReceiveShow" width="40%" title="领取客户" @ok="onSearchReceive">
        <div class="search-receive-model">
          <div class="search-receive-model-left">
            <ExclamationCircleTwoTone twoToneColor="#FFAB00" class="search-receive-model-left-lcon" />
          </div>
          <div class=" search-receive-model-right">
            <p>确定要领取该客户吗？</p>
            <p class=" search-receive-model-right-shallow">今日还可以领取{{ addTotalNum.highSeasSurplusNum }}条</p>
          </div>
        </div>
      </a-modal>
      <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">
        <template #title>
          <span class="title">新增</span>
        </template>
        <div>
          <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
            <a-form-item label="客户电话" name="phoneNumber">
              <a-input v-model:value="addForm.phoneNumber" placeholder="填写电话" />
            </a-form-item>
            <a-form-item label="客户微信">
              <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
            </a-form-item>
            <a-form-item label="客户报价">
              <a-input v-model:value="addForm.totalPrice" placeholder="填写客户报价" />
            </a-form-item>
            <a-form-item label="起始地点">
              <div style="display:flex;justify-content: space-around;">
                <a-cascader style="width:45%" v-model:value="start" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setStartAddress" placeholder="选择起始地" />
                <div>——</div>
                <a-cascader style="width:45%" v-model:value="end" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
              </div>
            </a-form-item>
            <!-- <a-form-item label="目的地点">
            </a-form-item> -->
            <!--            <a-form-item label="意向等级">-->
            <!--              <a-select allowClear v-model:value="addForm.intentionLevel" class="ransport_search_input">-->
            <!--                <a-select-option v-for="item in $store.state.app.labelAll.INTENTION_LEVEL" :key="item.value"> {{ item.label }}</a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
            <a-form-item label="渠道来源" name="channelSubIds">
              <a-cascader v-model:value="addForm.channelSubIds" :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" placeholder="选择渠道" />
            </a-form-item>
            <!-- <a-form-item label="渠道网址">
              <a-input v-model:value="addForm.url" />
            </a-form-item> -->
            <a-form-item>
              <template #label>
                <div>
                  <span>客户标签 </span>
                  <a style="font-size:12px" @click="addForm.tagId = ''"> 重置选择</a>
                </div>
              </template>
              <div>
                <a-radio-group v-model:value="addForm.tagId" button-style="solid">
                  <a-space>
                    <a-radio-button v-for="item in $store.state.enumAll.CustomerLabelType" :key="item.value" :value="item.value">{{ item.label }}</a-radio-button>
                  </a-space>
                </a-radio-group>
              </div>
            </a-form-item>
            <a-form-item label="备注">
              <a-input placeholder="填写备注" v-model:value="addForm.remark" />
            </a-form-item>
          </a-form>

        </div>
      </a-modal>
      <a-modal v-model:visible="searchPhoneShow" width="30%" @ok="onPhone">
        <template #title>
          <span class="title">领取主叫客户</span>
        </template>
        <a-form layout="vertical" :model="formState">
          <a-form-item label="请输入客户手机号">
            <a-input v-model:value="phone" />
          </a-form-item>
        </a-form>
      </a-modal>
    </template>
    <!-- 表格主体 -->
    <a-table size="small" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio' }" :columns="columns" :row-key="record => record.customerId" :data-source="listData" bordered :pagination="pagination" :loading="searchLoading" @change="handleTableChange">
      <template #channel="{ record }">
        {{ record.channelLabel }}<span v-if="record.channelLabel != null && record.channelSubLabel != null">-{{ record.channelSubLabel }}</span>
      </template>
      <template #customerName="{ record }">
        <span>
         {{   record.customerName  }}
        </span>
        <span v-if="record.tagNames">
          <div>
            <a-popover placement="bottom" title="标签">
              <template #content>
                <div style="max-width:500px;padding: 0 5px;"> <a-tag color="#009587" style="margin-bottom:5px" v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag></div>
              </template>
              <div style=display:flex>
                <div class="title-label" style="color:#F0F2F5;padding:0 5px;max-width:100px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;border-radius: 5px;background-color: #009587;">{{
                    record.tagNames.replaceAll(',',' ') }}
                </div>
              </div>
            </a-popover>
          </div>
          <!--        <a-tag color="#383838" v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag>-->
        </span>
      </template>
      <template #mobile="{ record }">
        <span v-if="record.mobile !== null && record.mobile !== ''">{{ record.mobile }}</span>
        <span v-else>{{ record.wechat }}</span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="onSee(record)">流转查看</a>
          <a-divider type="vertical" />
          <a @click="remind(record)">跟进查看</a>
          <a-divider type="vertical" />
          <a @click="clueQuery(record)">线索查看</a>
        </span>
      </template>
    </a-table>
    <a-drawer title="流转记录" width="760" placement="right" v-model:visible="followShow" destroyOnClose>
      <circulation ref="recodeCir" v-model:customerId="customerId" />
    </a-drawer>
    <!-- 跟进记录 -->
    <a-drawer title="跟进记录" width="760" placement="right" v-model:visible="reminShow" destroyOnClose>
      <followDetails :customerId="customerId" :followType="9" />
    </a-drawer>
    <!-- 线索 -->
    <a-drawer title="线索记录" width="80%" placement="right" v-model:visible="clueShow" destroyOnClose>
      <clueDetails :customerId="customerId" />
    </a-drawer>
  </HxTable>
  <a-modal v-model:visible="importantShow" title="批量导入" @ok="confirmImportant">
    <div style="color: #b91212; font-size: 12px">
      <p>提示：1、文档字段：电话，请按正确格式上传文件，否则上传不成功；</p>
      <p>&emsp;&emsp;&emsp;2、单次最多导入1000条。</p>
    </div>
    <div v-if="importantShow">
      <a-upload :before-upload="beforeUpload" @change="handleChange" v-model:fileList="fileList" :remove="handleRemove" class="upload-list-inline" :auto-upload="false" :accept="['.xlsx', '.xls']">
        <a-button> 选择文件 </a-button>
      </a-upload>
      <div>点击上传Excel文档 或 拖拽Excel文档至此，支持格式： .xls .xlsx</div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import HxTable from '@/components/HxTable'
import { mobileValidate } from '@/utils/validate'
import { ExclamationCircleTwoTone, createFromIconfontCN, RetweetOutlined } from '@ant-design/icons-vue'
import { page, call, receive, addPublic, allocate } from '@/api/crmManagement/temporary'
import { addTotal } from '@/api/crmManagement/tool'
import circulation from '../tool/circulation/index.vue'
import followDetails from '../tool/customerFollow/index.vue'
import clueDetails from '../tool/clue/detail'
import { employeeList } from '@/api/crmManagement/setRules'
import { carUpload } from '@/api/transport/transport'
import { uploadData } from '@/api/crmManagement/comSea'
import { message } from 'ant-design-vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
  // 定义局部自定义指令，这里是在 setup 标签下编写，指令名称以 v 开头，无需额外注册逻辑
  const ClickOutside = {
      mounted (el, binding) {
        function eventHandler (e) {
          if (el.contains(e.target)) {
            return false;
          }
          // 如果绑定的参数是函数，正常情况也应该是函数，执行
          if (binding.value && typeof binding.value === "function") {
            binding.value(e);
          }
        }
        // 用于销毁前注销事件监听
        el.__click_outside__ = eventHandler;
        // 添加事件监听
        document.addEventListener("click", eventHandler);
      },
      beforeUnmount (el) {
        // 移除事件监听
        document.removeEventListener("click", el.__click_outside__);
        // 删除无用属性
        delete el.__click_outside__;
      },
    };
export default {
  directives: { ClickOutside },
  setup () {
    const formRef = ref()
    const state = reactive({
      showBox: false,
      recodeCir: ref(),
      start: '',
      end: '',
      empId: '',
      fild: false,
      mandatory: false,
      importantShow: false,
      timer: undefined,
      options: [],
      fileList: [],
      file: null,
      searchReset: {},
      addTotalNum: {
        highSeasTotalNum: 0,
        highSeasNum: 0
      },
      phone: '',
      searchForm: {
        channel: 0
      },
      customerId: '',
      reminShow: false,
      clueShow: false,
      addForm: {
        wechat: '',
        phoneNumber: '',
        startAddress: {},
        endAddress: {}
      },
      selectedRowKeys: [],
      searchLoading: false,
      searchReceive: true,
      searchPhoneShow: false,
      searchReceiveShow: false,
      searchAddShow: false,
      followShow: false,
      columns: [
        {
          title: '客户电话/微信',
          dataIndex: 'mobile',
          width: '7%',
          slots: {
            customRender: 'mobile'
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          slots: {
            customRender: 'customerName'
          }
        },
        {
          title: '意向等级',
          dataIndex: 'intentionLevel.label',
          width: '5%'
        },
        {
          title: '线索分类',
          dataIndex: 'clueType.label',
          width: '5%'
        },
        {
          title: '渠道来源',
          dataIndex: 'channel',
          width: '11%',
          slots: {
            customRender: 'channel'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '8%'
        },
        {
          title: '前负责人',
          dataIndex: 'faller',
          width: '6%'
        },
        {
          title: '首次负责人',
          dataIndex: 'firstSaleName',
          width: '6%'
        },
        {
          title: '掉落时间',
          dataIndex: 'updateTime',
          width: '8%'
        },
        {
          title: 'IP归属地',
          dataIndex: 'ipAddress'
        },
        {
          title: '掉落原因',
          dataIndex: 'publicRemark'
        },
        {
          title: '查看信息',
          dataIndex: 'operation',
          width: 220,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        phoneNumber: [{ required: false, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        channelSubIds: { required: true, message: '请选择渠道来源', trigger: 'change', type: 'array' }
      },
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const cleItem = (e) => {
      state.searchForm.fallReason = e.label
      state.showBox = false
    }

    // 重置
    const reset = () => {
      state.searchForm = {}
      state.searchReset = {}
      loadData()
    }
    // 查询
    const onSearch = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    // 高级搜索方法
    const onSearchSenior = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    const batchImportant = () => {
      state.importantShow = true
    }
    /**
     * 每次提交检查渠道来源数据项
     */
    const setChannels = () => {
      if (state.searchReset.ChannelTypeEnum) {
        state.searchForm.channelId = state.searchReset.ChannelTypeEnum[0]
        if (state.searchReset.ChannelTypeEnum.length > 1) {
          state.searchForm.channelSubId = state.searchReset.ChannelTypeEnum[1]
        } else {
          state.searchForm.channelSubId = undefined
        }
      } else {
        state.searchForm.channelId = undefined
        state.searchForm.channelSubId = undefined
      }
    }
    const loadData = () => {
      state.searchLoading = true
      setChannels()
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.searchLoading = false
      })
      addTotalMethod()
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length > 0) {
        state.searchReceive = false
      } else {
        state.searchReceive = true
      }
    }
    // 渠道来源子类赋值
    const onChannel = (e) => {
      state.searchForm.children = e
    }
    // 领取客户
    const onSearchReceive = () => {
      if (state.selectedRowKeys[0] !== '') {
        receive(state.selectedRowKeys[0]).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.searchReceiveShow = false
            loadData()
          }
        })
      } else {
        message.error('客户id为null')
      }
    }
    // 新增
    const onAddForm = () => {
      if (state.addForm.phoneNumber !== '' || state.addForm.wechat !== '') {
        formRef.value.validate()
          .then(() => {
            const channelArr = state.addForm.channelSubIds
            state.addForm.channelId = channelArr[0]
            state.addForm.channelSubId = channelArr[1]
            delete state.addForm.channelSubIds
            if (!state.addForm.endAddress.province) state.addForm.endAddress = null
            if (!state.addForm.startAddress.province) state.addForm.startAddress = null
            addPublic(state.addForm).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                delete state.addForm.channelSubIds
                loadData()
                state.searchAddShow = false
                state.start = ''
                state.end = ''
                state.addForm = state.empty
                state.empty = JSON.parse(JSON.stringify(state.addForm))
              }
            })
          }).catch(err => { console.log(err) })
      } else message.error('电话和微信号必须填写其中一个')
    }
    // 每日剩余数量
    const addTotalMethod = () => {
      addTotal().then(res => {
        if (res.code === 10000) {
          state.addTotalNum = res.data
          state.addTotalNum.highSeasSurplusNum = state.addTotalNum.highSeasTotalNum - state.addTotalNum.highSeasNum
        }
      })
    }
    // 查看流转
    const onSee = (e) => {
      state.customerId = e.customerId
      state.followShow = true
    }
    // 主叫领取客户
    const onPhone = () => {
      call({ phone: state.phone }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.searchPhoneShow = false
          loadData()
        }
      })
    }

    const setChannel = (value, selectedOptions) => {
      state.searchForm.channelId = value[0]
      if (value.length > 1) {
        state.searchForm.channelSubId = value[1]
      }
    }
    const setStartAddress = (value, selectedOptions) => {
      state.addForm.startAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.startAddress.city = value[1]
      } else {
        state.addForm.startAddress.city = null
      }
      if (value.length > 2) {
        state.addForm.startAddress.area = value[2]
      } else {
        state.addForm.startAddress.area = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.addForm.endAddress.province = value[0]
      if (value.length > 1) {
        state.addForm.endAddress.city = value[1]
      }
      if (value.length > 2) {
        state.addForm.endAddress.area = value[2]
      }
    }

    const timeChange = (e, v) => {
      state.searchForm.startTime = v[0]
      state.searchForm.endTime = v[1]
    }
    const search = e => {
      if (e.keyCode === 13) {
        state.fild = true
        state.timer = setTimeout(() => {
          employeeList({
            current: 1,
            name: state.name,
            size: 10
          }).then(res => {
            if (res.code === 10000) {
              state.options = res.data
              state.fild = false
            }
          }).catch(err => { console.log(err) })
        }, 500)
      }
    }
    const assignment = e => {
      if (e !== '') state.name = e
    }
    const memberChange = (e, val) => {
      if (e.length > 1) {
        e.splice(0, 1)
        val.splice(0, 1)
        e[0] = val[0].value
      }
      if (val.length > 0) {
        state.empId = val[0].key
      }
      state.options = []
    }
    // 强制分配
    const distribution = () => {
      state.mandatory = false
      allocate({
        customerId: state.selectedRowKeys[0],
        empId: state.empId
      }).then(res => {
        if (res.code === 10000) {
          loadData()
        }
      }).catch(err => { console.log(err) })
    }
    const remind = record => {
      state.customerId = record.customerId
      state.reminShow = true
    }

    const clueQuery = record => {
      state.customerId = record.customerId
      state.clueShow = true
    }
    const handleRemove = (info) => {
      state.fileList = []
      state.file = null
    }
    const handleChange = async (info) => {
      state.file = info
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      state.fileList = resFileList;
    }
    const confirmImportant = async () => {
      console.log(state.fileList);
      if (state.fileList.length == 0) return
      // const asyncFun = async (item, index) => {
      await require(state.file.file)
      // }
      // for (let i = 0; i < state.fileList.length; i++) {
      // asyncFun(state.fileList[i], i)
      // }
      async function require (item) {
        const formData = new FormData()
        formData.append('file', item)
        carUpload(formData).then((res) => {
          if (res.code === 10000) {
            uploadData(res.data.id).then(res => {
              if (res.code === 10000) {
                message.success(item.name + '上传成功')
                state.fileList = []
                state.importantShow = false
              }
            }).finally(() => {
              state.importantShow = false
            })
          }
        })
      }
    }
    const beforeUpload = (info) => {
      return false
    }
  
    const conClickOutside =() => { 
      state.showBox = false
    }

    onMounted(loadData)
    return {
      ...toRefs(state),
      onSearch,
      reset,
      formRef,
      setStartAddress,
      setEndAddress,
      loadData,
      handleTableChange,
      onSelectChange,
      onSearchSenior,
      onChannel,
      onSearchReceive,
      beforeUpload,
      onAddForm,
      onSee,
      setChannel,
      onPhone,
      batchImportant,
      addTotalMethod,
      timeChange,
      confirmImportant,
      handleChange,
      search,
      assignment,
      memberChange,
      distribution,
      remind,
      clueQuery,
      handleRemove,
      cleItem,
      conClickOutside
    }
  },
  components: {
    HxTable,
    ExclamationCircleTwoTone,
    IconFont,
    RetweetOutlined,
    circulation,
    followDetails,
    clueDetails
  }
}
</script>
<style lang="less" scoped>
@import '../tool/index.less';
@import './index.less';
</style>
